import { styled } from "styled-components";

import { default as NavLink } from "components/Link";
import { linkStyle } from "components/Navigation/Navigation.styled";
import {
    styleHeadingLAlt,
    styleHeadingM,
    styleHeadingS,
} from "style/components/Typography";
import { MQ } from "style/mediaQueries";

export const Wrapper = styled.div`
    border-top: 1px solid var(--color-black-o15);
    margin-top: 1.25rem;
    padding-top: 1.25rem;

    ${MQ.FROM_XL} {
        border-left: 1px solid var(--color-black-o15);
        border-top: 0;
        margin-top: 0;
        padding-left: 3.5rem;
        padding-top: 0;
    }
`;

export const Heading = styled.h2`
    ${styleHeadingLAlt};
    margin-top: 0;
`;

export const NavShortcuts = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-bottom: 0.4375rem;
    padding: 0;
    row-gap: 0.4375rem;

    &:last-child {
        margin-bottom: 0;

        & li:last-child {
            margin-bottom: 0;
        }
    }

    ${MQ.FROM_XL} {
        margin-bottom: 0;
        row-gap: 0.875rem;
    }
`;

export const NavShortcutsItem = styled.li`
    list-style: none;

    &:last-child {
        ul {
            li:last-child {
                ${MQ.FROM_XL} {
                    margin-bottom: 0;
                }
            }
        }
    }
`;

export const NavShortcutsLink = styled(NavLink)`
    ${styleHeadingM};
    ${linkStyle};
    align-items: top;
    display: flex;
    gap: 0.875rem;
    min-height: 2.625rem;
    padding: 0.4375rem 0 0.375rem;

    > svg {
        flex-shrink: 0;
    }

    ${MQ.FROM_XL} {
        ${styleHeadingS};
        ${linkStyle};
    }
`;
